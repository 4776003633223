<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
    ></passContainer>
    <titleForm
      title="Editando el área de trabajo"
      :strong="editWork.name"
    ></titleForm>
    <form
      id="workAreaForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :defaultData="{
            name: editWork.name
          }"
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          submitName="Editar Área de Trabajo"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></first>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";

import { validateFormData } from "@/modules/tools";

import first from "@/views/workArea/form/1";

export default {
  name: "Work Area Edit",
  data() {
    return {
      sections: ["Datos Básicos"],
      activeSection: "",
      submitStart: false,
      showValidate: false,
      editWork: ""
    };
  },
  components: {
    Legal,
    passContainer,
    titleForm,
    first
  },
  created() {
    this.activeSection = this.sections[0];
    const store = new Storage();
    store.getData("workArea", this.$route.params._id).then(workArea => {
      this.editWork = workArea;
    });
  },
  methods: {
    submit() {
      const workAreaForm = document.getElementById("workAreaForm");
      const input = workAreaForm.getElementsByTagName("input");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(workAreaForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);

      Swal.fire({
        icon: "warning",
        title: "Editando área de trabajo",
        html:
          "Por favor espere mientras se edita el área de trabajo</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressDataForm" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        _id: this.$route.params._id,
        name: formData.get("name"),
        branch: this.editWork.branch,
        company: this.companyId
      };

      store
        .updateData("workArea", [data], true, "optima")
        .then(() => {
          this.endSubmit(true);
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Área de trabajo editada",
          text: "Todos los datos han sido sincronizados"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al editar el área de trabajo",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>
