<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="name" class="form-label">* Nombre</label>
        <input
          type="text"
          class="form-control"
          name="name"
          :minlength="nameMin"
          :maxlength="nameMax"
          v-model="name"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese un nombre para el área de trabajo
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      name: "",
      nameMin: 0,
      nameMax: 1
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "workArea") {
          this.nameMin = schema.data.name.min;
          this.nameMax = schema.data.name.max;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.name !== undefined && val.name !== null) {
              this.name = val.name;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Work Area Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
